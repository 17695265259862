export type Environment = 'local' | 'development' | 'staging' | 'production';

export const getEnvironment = (originUrl: string): Environment => {
  const origin = new URL(originUrl).origin;
  if (origin === 'http://localhost:9000') {
    return 'local';
  }

  if (origin === 'https://identity.dev.notion.so') {
    return 'development';
  }

  if (origin === 'https://identity.stg.notion.so') {
    return 'staging';
  }

  if (origin === 'https://identity.notion.so') {
    return 'production';
  }

  return 'production';
};

export const getNotionDomainBaseURL = (): string => {
  const env = getEnvironment(window.location.origin);
  switch (env) {
    case 'local':
      return 'http://localhost:3000';
    case 'development':
      return 'https://dev.notion.so';
    case 'staging':
      return 'https://stg.notion.so';
    case 'production':
      return 'https://www.notion.so';
    default:
      return 'https://dev.notion.so';
  }
};

export const getMailDomainBaseURLs = (): string[] => {
  const env = getEnvironment(window.location.origin);
  switch (env) {
    case 'local':
      return ['http://localhost:4201'];
    case 'development':
      return ['https://mail.dev.notion.so', 'https://app.mail.dev.notion.so'];
    case 'staging':
      return ['https://mail.stg.notion.so', 'https://app.mail.stg.notion.so'];
    case 'production':
      return ['https://mail.notion.so', 'https://app.mail.notion.so'];
    default:
      return ['https://mail.dev.notion.so', 'https://app.mail.dev.notion.so'];
  }
};
